import React from "react";
import axios from "axios";

function TestPage() {
  const response = axios
    .get(
      "https://port-0-userserver-am952nlt87j6ds.sel5.cloudtype.app/users/hello",
    )
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });

  return (
    <>
      <div>TestPage</div>
      <div>{response}</div>
    </>
  );
}

export default TestPage;
